const dogData = {
  dogs: [
  {
    id: 1,
    name: "Buddy",
    breed: "Goldendoodle",
    size: "Medium",
    age: "8 months",
    ageType: "Young",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmWLJwZs8EFYLjnRa2U5LE8cMM9THbXyd8MKDyrVmuA2Dy",
    cost: 410,
    goodWith: "kids and other dogs",
    coatLength: "Short",
    vaccinations: "Up to date, neutured",
    description: "Buddy is a friendly and energetic Labrador Retriever looking for a loving home.",
  },
  {
    id: 2,
    name: "Luna",
    breed: "Border Collie",
    size: "Small",
    age: "2 months",
    ageType: "Puppy",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmcYdiZM3ZEm2PneoAqNHXFehnXabD5MpQRZ8mynp45Ure",
    cost: 550,
    goodWith: "kids, other dogs, and cats",
    coatLength: "Medium",
    vaccinations: "Up to date",
    description: "Luna is a gentle and loyal Golden Retriever ready to be your companion.",
  },
  {
    id: 3,
    name: "Max",
    breed: "German Shepherd",
    size: "Large",
    age: "3 years",
    ageType: "Adult",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmW1EzS4JvdSp3gtBbuPLsQXzXTP8BLRmMYaRv99NGKxSa",
    cost: 600,
    goodWith: "kids and other dogs",
    coatLength: "Long",
    vaccinations: "Up to date, neutered",
    description: "Max is a smart and protective German Shepherd looking for an experienced owner.",
  },
  {
    id: 4,
    name: "Daisy",
    breed: "Pitbull",
    size: "Large",
    age: "3 months",
    ageType: "Puppy",
    gender: "Female",
    image: "https://ipfs.io/ipfs/Qmem2mRPeUFYH9fGPMUpdacMiinoYq5fCXTTKBiZuorVdP",
    cost: 300,
    goodWith: "kids and other dogs",
    coatLength: "Short",
    vaccinations: "Up to date",
    description: "Daisy is a curious and friendly Beagle who loves to explore.",
  },
  {
    id: 5,
    name: "Charlie",
    breed: "Great Pyrenees",
    size: "Medium",
    age: "3 months",
    ageType: "Puppy",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmTK4rup9vUaTwheeS6BdtRiDHWbTY3BTy7pir2yA7NjzV",
    cost: 480,
    goodWith: "kids and other dogs",
    coatLength: "Long",
    vaccinations: "Up to date",
    description: "Charlie is an adorable and playful Poodle looking for a forever home.",
  },
  {
    id: 6,
    name: "Lucy",
    breed: "Border Collie",
    size: "Medium",
    age: "5 months",
    ageType: "Young",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmU1p7qYnUD9Qf2fmhh5jfz9Vzf61zPr7gfnUyfsa6qxXF",
    cost: 380,
    goodWith: "kids and cats",
    coatLength: "Medium",
    vaccinations: "Up to date, spayed",
    description: "Lucy is a sweet and gentle Cocker Spaniel with a lovely personality.",
  },
  {
    id: 7,
    name: "Rocky",
    breed: "Boxer",
    size: "Large",
    age: "2 months",
    ageType: "Puppy",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmY8BiyDgxJukDAzqjomAGPucvmKgUkueu8yFxUhANuxHg",
    cost: 500,
    goodWith: "kids and other dogs",
    coatLength: "Short",
    vaccinations: "Up to date",
    description: "Rocky is a strong and friendly Boxer who loves to play and cuddle.",
  },
  {
    id: 8,
    name: "Coco",
    breed: "Pugg",
    size: "Small",
    age: "3 years",
    ageType: "Adult",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmdEVMDxoz442fpodEFqAKwNZb9bvMV9gxP2dyPRFPAVEn",
    cost: 350,
    goodWith: "kids and other dogs",
    coatLength: "Long",
    vaccinations: "Up to date, not spayed (under 7 months)",
    description: "Coco is a charming and affectionate Shih Tzu with a fluffy coat.",
  },
  {
    id: 9,
    name: "Bailey",
    breed: " Labrador",
    size: "Medium",
    age: "3 months",
    ageType: "Puppy",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmWrNFiR5XXP9ULNDrHkcfMSaJk4GeSmyqRvHz1eU7HJSF",
    cost: 480,
    goodWith: "kids and other dogs",
    coatLength: "Medium",
    vaccinations: "Up to date",
    description: "Bailey is an intelligent and energetic Australian Shepherd ready for an active family.",
  },
  {
    id: 10,
    name: "Molly",
    breed: "Golden Retriever",
    size: "Large",
    age: "3 months",
    ageType: "Puppy",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmV7XTNMfNSJdXm3hUARq8q1Sw6Bp4mHVxdz8YGYTGmGAf",
    cost: 420,
    goodWith: "kids and other dogs",
    coatLength: "Short",
    vaccinations: "Up to date, spayed",
    description: "Molly is a friendly and affectionate Bulldog with a unique personality.",
  },

  ],
};






export default dogData;
