const catData = {
  cats: [
  {
    id: 1,
    name: "Milo",
    breed: "Siamese",
    size: "Small",
    age: "3 months",
    ageType: "Kitten",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmcqDzyEZyxfeZobKRz4pmZK2pGHXDvkEqZzgDvHN3w6S5",
    cost: 250,
    goodWith: "kids and other cats",
    coatLength: "Short",
    vaccinations: "Up to date",
    description: "Milo is an adorable Siamese kitten with striking blue eyes.",
  },
  {
    id: 2,
    name: "Luna",
    breed: "Maine Coon",
    size: "Large",
    age: "1.5 years",
    ageType: "Young ",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmayWHwGGH2QXYH59sGbWoYgsLYNxTYu1Y9UcarVBtSFji",
    cost: 400,
    goodWith: "kids and other cats",
    coatLength: "Long",
    vaccinations: "Up to date, spayed",
    description: "Luna is a majestic Maine Coon known for her affectionate nature.",
  },
  {
    id: 3,
    name: "Oliver",
    breed: "Tabby",
    size: "Medium",
    age: "2 years",
    ageType: "Adult",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmbKtLXbJhMKeGit9CGyFLkadASxNQexsigJuUQXtgWpwW",
    cost: 300,
    goodWith: "kids and other cats",
    coatLength: "Short",
    vaccinations: "Up to date, neutered",
    description: "Oliver is a friendly and playful Tabby looking for a loving home.",
  },
  {
    id: 4,
    name: "Bella",
    breed: "Persian",
    size: "Medium",
    age: "8 months",
    ageType: "Kitten",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmSZcvqczAEZoyXxNYuZSWpYW91hoUqzcLDcMDvxEPjYRv",
    cost: 350,
    goodWith: "kids and other cats",
    coatLength: "Long",
    vaccinations: "Up to date, not spayed (under 7 months)",
    description: "Bella is a beautiful Persian kitten with a luxurious coat.",
  },
  {
    id: 5,
    name: "Charlie",
    breed: "Bengal",
    size: "Medium",
    age: "1 year",
    ageType: "Young ",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmVBqLdBmnvKmXu2qqjPGniqb6UMsrRgj6dqibRXoAScx4",
    cost: 500,
    goodWith: "kids and other cats",
    coatLength: "Short",
    vaccinations: "Up to date, neutered",
    description: "Charlie is a curious and energetic Bengal cat looking for an active household.",
  },
  {
    id: 6,
    name: "Mia",
    breed: "Russian Blue",
    size: "Small",
    age: "2 years",
    ageType: "Adult",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmQhDbLaQGHoGEKHXbmMNXb9keZPyX3vsEsuMdMNct8GfN",
    cost: 380,
    goodWith: "kids and other cats",
    coatLength: "Short",
    vaccinations: "Up to date, spayed",
    description: "Mia is a gentle and elegant Russian Blue with a serene personality.",
  },
  {
    id: 7,
    name: "Max",
    breed: "Ragdoll",
    size: "Large",
    age: "3 years",
    ageType: "Adult",
    gender: "Male",
    image: "https://ipfs.io/ipfs/Qmc397m3GK1VkMSWXDMPD8LwDWTgonf5QGsrmdB8yKgEmW",
    cost: 450,
    goodWith: "kids and other cats",
    coatLength: "Long",
    vaccinations: "Up to date, neutered",
    description: "Max is a laid-back and affectionate Ragdoll cat who enjoys cuddles.",
  },
  {
    id: 8,
    name: "Sophie",
    breed: "Sphynx",
    size: "Small",
    age: "1.5 years",
    ageType: "Young ",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmdALjwP3wC2mt8CkTT6QH7jmuuJ9aZ7njurgDniEdAEhe",
    cost: 550,
    goodWith: "kids and other cats",
    coatLength: "Hairless",
    vaccinations: "Up to date, spayed",
    description: "Sophie is a unique and friendly Sphynx cat with a warm personality.",
  },
  {
    id: 9,
    name: "Leo",
    breed: "Scottish Fold",
    size: "Medium",
    age: "1 year",
    ageType: "Young ",
    gender: "Male",
    image: "https://ipfs.io/ipfs/QmVdTNohHRNTLHBMAPEpHCJ6kyXs4b31wuUrdewDSX3vbw",
    cost: 400,
    goodWith: "kids and other cats",
    coatLength: "Short",
    vaccinations: "Up to date, neutered",
    description: "Leo is a charming Scottish Fold cat known for his distinctive folded ears.",
  },
  {
    id: 10,
    name: "Chloe",
    breed: "Himalayan",
    size: "Small",
    age: "2 years",
    ageType: "Adult",
    gender: "Female",
    image: "https://ipfs.io/ipfs/QmbjTDUVukuXk6rLcsDrcTHtotRY9W82VnoSgXaQ5actKc",
    cost: 420,
    goodWith: "kids and other cats",
    coatLength: "Long",
    vaccinations: "Up to date, spayed",
    description: "Chloe is a graceful Himalayan cat with a calm and regal presence.",
  },
 ],
};

export default catData;